import { Link, useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Loader } from "../components/Loader";
import { IconBackBlue } from "../icons";
import { renderWithLineBreaks } from "../utils";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { makeRequest } from "../utils/request";

export const ManualPage = () => {

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [result, setResult] = useState({});
    const history = useHistory();
    let { query } = useParams();

    const onGoBack = useCallback(() => {
        history.goBack();
    }, [history])

    useEffect(() => {
        makeRequest(`/manual?q=${query}`, 'get', {
        }).then((res) => {
            if (res.data.status) {
                setResult(res.data.result);
                setIsLoading(false);
            }
        })
    }, [query])

    const renderElems = (data) => {
        switch (data.type) {
            case 'button_back':
                return (
                    <div key={data.type} className="text-[#0073ff] text-[18px] mt-[19px]">
                        <Link onClick={onGoBack} className="flex items-center">
                            <IconBackBlue />
                            <span className='inline-block ml-[7px]'>{renderWithLineBreaks(data.caption)}</span>
                        </Link>
                    </div>
                )
            case 'title':
                return (
                    <div key={data.type} className="text-[30px] font-bold text-[#232948] mt-[15px] leading-[1.23]">
                        {data.caption}
                    </div>
                )
            case 'text':
                return (
                    <div key={data.type + data.caption.slice(0,10)} className="mt-[22px] max-w-[327px] text-[18px] leading-[1.22] text-[#242a46] tracking-[normal]">
                        {renderWithLineBreaks(data.caption)}
                    </div>
                )
            case 'img':
                return (
                    <img key={data.type} alt="" src={data.caption} className="w-[327px] h-[327px] rounded-[16px] mt-[30px]" />
                )
            default: return <></>
        }
    }

    return (
        <>
            {isLoading ? <div>
                <Loader />
            </div> : <div className='bg-white  min-h-screen  flex flex-col items-center'>
                <div className="max-w-[335px] w-full pt-[40px]">
                    {result.content.map((elem) => renderElems(elem))}
                </div>
            </div>}
        </>
    )
}