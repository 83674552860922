import { ScoreBlock } from "../components/NewMainPage/ScoreImage";
import { LogoIcon } from "../images/new-main-page";
import { CameraIcon, NewLogoIcon, StarsBrownIcon, StarsIcon, UploadIcon, ZoomInIcon } from '../images/main-page-yellow';
import bgHeaderSrc from '../images/main-page-yellow/header-bg.png';
import bgSrc from '../images/main-page-yellow/bg.png';
import scoreSymmetrySrc from '../images/main-page-yellow/score-symmetry.png';
import scoreProportionsSrc from '../images/main-page-yellow/score-proportions.png';
import shareManySrc from '../images/main-page-yellow/double-photo.png';
import ImageWithBg from '../images/main-page-yellow/img-with-bg.png';
import resultScoreSrc from '../images/new-main-page/result-example.png';
import resultRatingSrc from '../images/main-page-yellow/rating-combined.png';
import bendOvalSrc from '../images/main-page-yellow/bend-oval.png';
import personSrc from '../images/new-main-page/person-photo.png';
import imageCenterSrc from '../images/new-main-page/image-center.webp';
import videoPotentialSrc from '../images/video-potential.gif';
import imageLeftSrc from '../images/new-main-page/image-left.png';
import imageRightSrc from '../images/new-main-page/image-right.png';
import imageBorder from '../images/new-main-page/carousel-border.png';
import neutralTextSrc from '../images/main-page-yellow/Neutral-text.png'
import imageExampleSrc from '../images/main-page-yellow/example-image.png'
import faceLogoSrc from '../images/main-page-yellow/Face-Logo.png';

import { Bullet } from "../components/NewMainPage/Bullet";
import facebookSrc from '../images/new-main-page/facebook.png';
import youtubeSrc from '../images/new-main-page/youtube.png';
import xSrc from '../images/new-main-page/x.png';
import lndinSrc from '../images/new-main-page/lndin.png';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import { renderWithLineBreaks } from "../utils";
import { LogoBlackIcon } from "../icons";
import CircleProgressBar from "../components/ProgressBar";
import exampleImageStraight from '../images/main-image.webp';

export function NewMainPage({ onClickLoadingElem, historyBlock, onClickHistoryBlock, onClickFeedBack, reportExamples }) {
    const { t } = useTranslation();
    const onClickReport = (link) => {
        window.location.href = link
    }

    const renderCard = ({ score, img, title, url }) => {
        return (
            <div key={title + score} className="w-[274px] h-[395px] rounded-[20px] bg-[#EDE3DD] overflow-hidden">
                <div className="mt-[16px] px-[12px] h-[36px] flex justify-between w-full">
                    <div className="flex items-center gap-[8px]">
                        <div className="h-[36px] w-[36px] relative flex justify-center items-center">
                            <CircleProgressBar className='absolute left-0 top-0 bottom-0 right-0 z-[1]' smallMode={true} customSize={36} score={score} mainColor={'#F58238'} secondColor={'#FFF'} />
                            <div className="text-[15px] mt-[1.5px] leading-[15px] font-[600] text-[#6E5D50]">
                                {score}
                            </div>
                        </div>
                        <div className="font-satoshi font-[700] text-[15px] text-[#6E5D50]">
                            {title}
                        </div>
                    </div>

                    <button onClick={() => { onClickReport(url) }} className="h-[35px] self-center px-[16px] flex items-center gap-[8px] justify-center bg-[#000] rounded-[48px] text-[15px] font-satoshi text-[#fff] font-[500]">
                        Show
                    </button>
                </div>
                <img src={img} alt="" className="mt-[12px] w-[274px] h-[331px]" />
            </div>
        )

    }

    return (

        <div className="w-full min-w-[375px] flex flex-col items-center overflow-hidden">

            <div className="w-full  flex flex-col items-center">
                <div style={{ backgroundImage: `url(${bgHeaderSrc})` }} className="bg-cover bg-center pt-[30px] pb-[38px] w-full flex flex-col rounded-b-[40px]">
                    <div className="flex mx-[12px] md:mx-[40px] lg:mx-[60px] justify-between">
                        <LogoBlackIcon />

                        {/* <button className="bg-[#586BFF] px-[16px] py-[10px] rounded-[48px] text-[14px] font-satoshi text-[#fff] font-[500]">
                            Sign In
                        </button> */}

                        {/* {historyBlock.photoCount > 0 ? <div onClick={onClickHistoryBlock} className='min-w-[124px] self-center h-[40px] flex rounded-[48px]  bg-[#586BFF] bg-opacity-15 cursor-pointer pr-[12px]'>
                            <img src={historyBlock.photoSrc} alt="" className='h-[40px] w-[40px] rounded-[32px] ' />
                            <div className='ml-[10px] text-[#140D32] font-satoshi self-center text-[14px] leading-[24px]'>
                                {historyBlock.caption}
                            </div>
                        </div> : <div></div>} */}

                        <button onClick={onClickFeedBack} className=" h-[35px] self-center px-[16px] flex items-center gap-[8px] justify-center bg-[#000] rounded-[48px] text-[15px] font-satoshi text-[#fff] font-[500]">
                            {renderWithLineBreaks(t('UPLOAD_SUPPORT'))}
                        </button>


                    </div>

                    <div className="h-[276px] mt-[118px] w-full flex items-center gap-[9px] overflow-hidden justify-center">
                        <img src={process.env.PUBLIC_URL + '/main-image-left-new.webp'} alt=""
                            width="234px" height="276px"
                            className=" ml-[-147px]  rounded-[16px] "
                        />
                        <div className="w-[234px] min-w-[234px] h-[276px] relative ">
                            <img src={process.env.PUBLIC_URL + '/image-center-new.webp'} width="234px" height="276px" alt=""
                                className="rounded-[16px]"
                            />
                            <img src={imageBorder} alt="" className="w-[167px] h-[165px] absolute top-[40px] left-[26px]" />
                        </div>

                        <img src={process.env.PUBLIC_URL + '/main-image-right-new.webp'} alt="" width="234px" height="276px" className=" mr-[-147px]" />
                    </div>

                    <div className="font-satoshi mt-[32px] text-center text-[54px] md:text-[68px] font-[500] text-[#FFF] leading-[54px] md:leading-[68px] tracking-[-2.16px] px-[24px]">
                        {renderWithLineBreaks(t('UPLOAD_MESSAGE_TITLE'))}
                    </div>
                    <div className="mt-[20px] font-satoshi text-center font-[500] text-[21px] text-[#FFF] tracking-[-0.21px] leading-[normal] px-[24px]">
                        {renderWithLineBreaks(t('UPLOAD_MESSAGE_SUBTITLE'))}
                    </div>

                    <button onClick={onClickLoadingElem} className="mt-[16px] md:mt-[98px] h-[48px] self-center w-[188px] flex items-center gap-[8px] justify-center bg-[#000] rounded-[48px] text-[17px] font-satoshi text-[#fff] font-[700]">
                        <StarsIcon />
                        {renderWithLineBreaks(t('UPLOAD_BUTTON'))}
                    </button>
                    <div className="mt-[22px] flex self-center items-center justify-center gap-[6px]  text-[15px] font-[700] font-satoshi text-[#fff]">
                        <CameraIcon />
                        {renderWithLineBreaks(t('UPLOAD_NOTICE'))}
                    </div>
                </div>


                <div className=" w-full mt-[60px] lg:mt-[120px] bg-[white] flex flex-col">
                    <div className="font-satoshi text-center text-[42px] md:text-[54px] leading-[42px] md:leading-[54px] text-[#000] font-[500] tracking-[-2.16px] px-[24px]">
                        Report examples
                    </div>
                    <div className="text-center mt-[12px] text-[21px] font-[500] text-[#A18875] font-satoshi tracking-[-0.21px] px-[24px]">
                        Look at different reports, that other people created with FaceQ
                    </div>
                    <div className="semi-lg:w-[900px] max-w-full w-full semi-lg:overflow-hidden overflow-y-scroll remove_scroll semi-lg:self-center mt-[60px] ml-[16px] semi-lg:ml-0">
                        <div className=" flex gap-[20px] w-[900px]">
                            {reportExamples.map((ex) => renderCard(ex))}
                        </div>
                    </div>


                </div>

                <div className="bg-[linear-gradient(180deg,#EDE3DD_0%,#FFF_100%)] w-full flex flex-col items-center mt-[64px] pt-[60px] pb-[60px]">
                    <div className="font-satoshi text-[15px] font-[700] text-[#6E5D50] tracking-[2.25px] uppercase text-center border-[2px] border-solid border-[#6E5D50] px-[16px] py-[2px] rounded-[40px]">
                        {renderWithLineBreaks(t('UPLOAD_SELFIE_STEP'))}
                    </div>
                    <div className="font-satoshi text-center mt-[12px] px-[24px] text-[42px] font-[500] text-[#140D32] leading-[42px] tracking-[-0.84]">
                        {renderWithLineBreaks(t('UPLOAD_SELFIE_TITLE'))}
                    </div>


                    <div style={{ backgroundImage: `url(${bgSrc})` }} className="mt-[24px] bg-cover w-[290px] rounded-[36px] pb-[24px] flex flex-col items-center">
                        <img src={ImageWithBg} className="h-[365px] w-[290px]" alt="" />
                        <button onClick={onClickLoadingElem} className="mt-[24px] h-[36px] self-center w-[156px] flex items-center gap-[8px] justify-center bg-[#000] rounded-[36px] text-[15px] font-satoshi text-[#fff] font-[500]">
                            <UploadIcon />
                            {renderWithLineBreaks(t('UPLOAD_SELFIE_BUTTON'))}
                        </button>
                    </div>
                    {/* 
                    <img src={process.env.PUBLIC_URL + '/main-image-center.webp'} loading="lazy" className="w-[289px] h-[338px] mt-[32px] rounded-[24px] self-center " alt="" />
                    <button onClick={onClickLoadingElem} className="mt-[32px] h-[48px] self-center w-[188px] flex items-center justify-center bg-[#586BFF] rounded-[48px] text-[14px] font-satoshi text-[#fff] font-[500]">
                        {renderWithLineBreaks(t('UPLOAD_SELFIE_1_BUTTON_IMG'))}
                    </button> */}
                </div>

                <div style={{ backgroundImage: `url(${bgSrc})` }} className="bg-cover w-full max-w-[600px] lg:w-full lg:max-w-full flex flex-col items-center mt-[80px] rounded-[40px] lg:pt-[80px] lg:rounded-none pt-[32px] pb-[34px]">
                    <div className="font-satoshi text-[15px] font-[700] text-[#6E5D50] tracking-[2.25px] uppercase text-center border-[2px] border-solid border-[#6E5D50] px-[16px] py-[2px] rounded-[40px]">
                        {renderWithLineBreaks(t('UPLOAD_SCORE_STEP'))}
                    </div>

                    <div className="font-satoshi text-center text-[42px] font-[500] px-[24px] text-[#000] leading-[42px] tracking-[-0.84px] mt-[12px]">
                        {renderWithLineBreaks(t('UPLOAD_SCORE_TITLE'))}
                    </div>
                    {/* <div className="mt-[32px] w-[139px] h-[162px] self-center overflow-hidden bg-transparent"> */}
                    <img src={shareManySrc} loading="lazy" alt="" className="w-[322px] h-[236px] self-center mt-[32px]" />
                    {/* </div> */}
                    <img src={resultRatingSrc} loading="lazy" alt="" className="w-[250px] h-[140px] self-center" />

                    <div className="mt-[-55px] font-satoshi text-[20px] font-[900] text-[#000] leading-[130%] tracking-[-0.4px] uppercase text-center">
                        {renderWithLineBreaks(t('UPLOAD_SCORE_STATUS_1'))}
                        <br />
                        <span className="text-[#F18B26] font-[900] leading-[130%]">
                            &nbsp;{renderWithLineBreaks(t('UPLOAD_SCORE_STATUS_2'))}
                        </span>
                    </div>

                    <ZoomInIcon className="self-center mt-[44px]" />
                    <div className="mt-[4px] font-satoshi font-[500] text-[#6E5D50] text-[15px] tracking-[-0.15px] leading-[normal] px-[24px] text-center">
                        {renderWithLineBreaks(t('UPLOAD_SCORE_NOTICE'))}
                    </div>
                </div>

                <div className="font-satoshi mt-[60px] text-[15px] font-[700] text-[#6E5D50] tracking-[2.25px] uppercase text-center border-[2px] border-solid border-[#6E5D50] px-[16px] py-[2px] rounded-[40px]">
                    {renderWithLineBreaks(t('UPLOAD_ANALYSIS_STEP'))}
                </div>

                <div className="font-satoshi mt-[12px] text-center text-[42px] font-[500] px-[24px] text-[#000] leading-[42px] tracking-[-0.84px]">
                    {renderWithLineBreaks(t('UPLOAD_ANALYSIS_TITLE'))}
                </div>

                <div className="mx-[24px] mt-[32px] self-stretch md:self-center md:w-[600px] lg:w-[600px]">
                    <div className="flex w-full gap-[12px]"  >
                        <div className="h-[195px] w-full rounded-[24px] pt-[24px] bg-cover flex flex-col items-center" style={{ backgroundImage: `url(${bgSrc})` }} >
                            <img alt="" className="w-[112px] h-[112px]" src={scoreSymmetrySrc} />
                            <div className="mt-[22px] uppercase text-[13px] font-satoshi font-[500] text-[#000] opacity-40">
                                {renderWithLineBreaks(t('UPLOAD_ANALYSIS_1_CAPTION'))}
                            </div>
                        </div>

                        <div className="h-[195px] w-full rounded-[24px] pt-[24px] bg-cover flex flex-col items-center" style={{ backgroundImage: `url(${bgSrc})` }} >
                            <img alt="" className="w-[112px] h-[112px]" src={scoreProportionsSrc} />
                            <div className="mt-[22px] uppercase text-[13px] font-satoshi font-[500] text-[#000] opacity-40">
                                {renderWithLineBreaks(t('UPLOAD_ANALYSIS_2_CAPTION'))}
                            </div>
                        </div>
                    </div>

                    <div className="w-full mt-[12px] bg-cover h-[196px] rounded-[24px] pt-[20px] flex flex-col items-center" style={{ backgroundImage: `url(${bgSrc})` }}>
                        <div className="uppercase text-[13px] font-satoshi font-[500] text-[#000] opacity-40">
                            {renderWithLineBreaks(t('UPLOAD_ANALYSIS_3_CAPTION'))}
                        </div>

                        <img className="w-[136px] h-[68px] mt-[20px]" alt="" src={bendOvalSrc} />
                        <div className="uppercase text-[13px] mt-[20px] font-satoshi font-[500] text-[#000] opacity-40">
                            {renderWithLineBreaks(t('UPLOAD_ANALYSIS_3_VALUE'))}
                        </div>
                    </div>

                    <div className="w-full mt-[12px] bg-cover rounded-[24px] flex justify-between items-center px-[16px] h-[53px]" style={{ backgroundImage: `url(${bgSrc})` }}>
                        <div className="uppercase text-[13px] leading-[13px] font-satoshi font-[500] text-[#000] opacity-40">
                            {renderWithLineBreaks(t('UPLOAD_ANALYSIS_4_CAPTION'))}
                        </div>
                        <img src={neutralTextSrc} alt="" className="h-[17px] w-[69px]" />
                    </div>
                </div>



                <div style={{ backgroundImage: `url(${bgSrc})` }} className="bg-cover w-full max-w-[600px] lg:max-w-[924px] flex flex-col items-center mt-[60px] rounded-[40px]  pt-[32px] pb-[16px]">
                    <div className="font-satoshi text-[15px] font-[700] text-[#6E5D50] tracking-[2.25px] uppercase text-center border-[2px] border-solid border-[#6E5D50] px-[16px] py-[2px] rounded-[40px]">
                        {renderWithLineBreaks(t('UPLOAD_IMAGE_STEP'))}
                    </div>

                    <div className="font-satoshi text-center text-[42px] px-[24px] font-[500] text-[#000] leading-[42px] tracking-[-0.84px] mt-[12px]">
                        {renderWithLineBreaks(t('UPLOAD_IMAGE_TITLE'))}
                    </div>

                    <img alt="" className="w-[255px] h-[278px] mt-[32px] rounded-[36px]" src={videoPotentialSrc} />

                    <button onClick={onClickLoadingElem} className="mt-[32px] h-[36px] self-center w-[156px] flex items-center gap-[8px] justify-center bg-[#000] rounded-[36px] text-[15px] font-satoshi text-[#fff] font-[500]">
                        <UploadIcon />
                        {renderWithLineBreaks(t('UPLOAD_IMAGE_BUTTON'))}
                    </button>


                    <StarsBrownIcon className="self-center mt-[32px]" />
                    <div className="mt-[4px] font-satoshi font-[500] text-[#6E5D50] px-[24px] text-[15px] tracking-[-0.15px] leading-[normal] px-[40px] text-center">
                        {renderWithLineBreaks(t('UPLOAD_IMAGE_NOTICE'))}
                    </div>
                </div>

                <img src={faceLogoSrc} className="mt-[32px] w-[102px] h-[40px]" alt="" />

                <footer className="w-full flex flex-col  items-center py-[40px] ">
                    {/* <div className="w-[186px] flex justify-between items-center">
                        <img loading="lazy" src={facebookSrc} className="w-[24px] h-[24px]" alt="" />
                        <img loading="lazy" src={youtubeSrc} className="w-[31px] h-[21px]" alt="" />
                        <img loading="lazy" src={xSrc} className="w-[24px] h-[22px]" alt="" />
                        <img loading="lazy" src={lndinSrc} className="w-[23px] h-[24px]" alt="" />
                    </div> */}
                    <div className="font-satoshi text-[15px] font-[500] tracking-[-0.15px] text-[#BBB]">
                        {/* {renderWithLineBreaks(t('COPYRIGHT'))} */}
                        {renderWithLineBreaks(t('COPYRIGHT'))}
                    </div>

                    <div className="mt-[40px]  tracking-[-0.15px] font-satoshi text-[15px] font-[500] text-[#BBB] gap-[24px] flex">
                        <Link className="underline" to="/pri">{renderWithLineBreaks(t('UPLOAD_PRIVACY'))}</Link >
                        <Link className="underline" to="/ter">{renderWithLineBreaks(t('UPLOAD_TERMS'))}</Link>
                    </div>
                </footer>
            </div>
        </div >
    )
}